/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  float: right;
}
.sub1 > .item {
  margin: 0 10px;
}
.sub1 > .item.exit {
  margin-right: 0;
}
.sub1 > .item > div.sub2 {
  display: none;
}
div.sub1 .menu {
  font-size: 15px;
  font-stretch: 75%;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-decoration: none;
  line-height: 1;
}
div.sub1 a.path {
  color: #e2001a;
}
/* LEVEL 2 */
div.sub2 {
  float: right;
  margin-top: 4px;
}
.sub2 > .item {
  padding: 0 16px 0 8px;
  background: url(/images/slash.png) no-repeat 100% 50%;
}
.sub2 > .item.exit {
  padding-right: 0;
  background: none;
}
div.sub2 .menu {
  font-size: 14px;
  text-decoration: none;
}
div.sub2 a.path {
  color: #e2001a;
}
#social {
  position: absolute;
  top: -78px;
  left: 0;
  width: 100%;
  text-align: center;
}
#social .meta {
  display: inline-block;
  width: 48px;
  margin: 0 5px;
  height: 48px;
  text-indent: -9999px;
  overflow: hidden;
  transition: all 0.218s;
  background-size: 240px 48px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/social-footer-2020-12-22.svg);
}
#social .meta:hover,
#social .meta:focus {
  opacity: 0.6;
}
#social .meta.service_xing {
  background-position: -48px 0;
}
#social .meta.service_linkedin {
  background-position: -96px 0;
}
#social .meta.service_facebook {
  background-position: -144px 0;
}
#social .meta.service_instagram {
  background-position: -192px 0;
}
#cmsbox {
  position: absolute;
  left: 0;
  bottom: 15px;
  color: #525252;
  font-size: 11px;
}
#cmsbox .meta {
  color: #525252;
  margin: 0 10px;
  text-decoration: none;
  font-size: 11px;
}
#cmsbox .meta:hover,
#cmsbox .meta:focus {
  color: #fff;
}
#cmsbox .meta.firm {
  margin-left: 0;
}
/******* layout-large.less 2013-1-16 *******/
#home {
  float: left;
}
#head {
  position: absolute;
  bottom: 0;
  padding-bottom: 1.95652174%;
  right: 3.26086957%;
  width: 600px;
  z-index: 3;
}
#mobile-navi,
.open-navi {
  display: none;
}
a.blog {
  display: block;
}
#navigation {
  display: block;
}
#multimood {
  margin-top: 14px;
}
#hj-contentbottom,
#hj-contenttop {
  width: 103%;
  margin-left: -1.5%;
}
.hj-part {
  width: 30.33333333%;
  margin-left: 1.5%;
  margin-right: 1.5%;
}
.hj-title {
  width: 97%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  padding-bottom: 0;
}
#footer .foot-part {
  padding: 40px 0;
  width: 20%;
}
#footer .foot-part.first {
  width: 31.52173913%;
}
#footer .foot-part.second {
  margin-left: 2.17391304%;
  width: 15.76086957%;
}
#footer .foot-part.third {
  margin-left: 1.08695652%;
  width: 11.73913043%;
}
#footer .foot-part.fourth {
  margin-left: 5%;
  width: 10.86956522%;
}
#footer .foot-part.fifth {
  margin-left: 5.97826087%;
  width: 15.86956522%;
}
.foot-part.fourth .foot-title.plus {
  margin-top: 27px;
}
.foot-part.fifth .foot-title.plus {
  margin-top: 52px;
}
#footer .foot-part {
  border: none;
  margin-left: 0;
}
#footer .foot-part.third,
#footer .foot-part.fifth {
  display: block;
}
#footer .foot-part.fourth {
  border-bottom: none;
}
#footer .foot-part .foot-title.plus {
  border-top: none;
  padding: 0;
  margin-left: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.85470085%;
  margin-left: 0.85470085%;
}
.area .part,
.area > .grid table {
  margin-right: 0.85470085%;
  margin-left: 0.85470085%;
  width: 98.29059829%;
}
.area .tiny {
  width: 48.29059829%;
}
.area > .slim {
  width: 33.33333333%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2.56410256%;
  margin-left: 2.56410256%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 94.87179487%;
}
.area > .slim .tiny {
  width: 94.87179487%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 2.47933884%;
  margin-left: 2.47933884%;
}
.base .part,
.base > .grid table {
  margin-right: 2.47933884%;
  margin-left: 2.47933884%;
  width: 95.04132231%;
}
.base .tiny {
  width: 45.04132231%;
}
.base > .slim {
  width: 25%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 9.91735537%;
  margin-left: 9.91735537%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 80.16528926%;
}
.base > .slim .tiny {
  width: 80.16528926%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  margin-left: -0.86956522%;
  width: 101.73913043%;
}
.base {
  margin-left: -2.60869565%;
  width: 105.2173913%;
}
.base div.wide {
  width: 50%;
}
.base div.wide div.head h2 {
  width: 90.08264463%;
  margin-left: 4.95867769%;
  margin-right: 4.95867769%;
}
.base div.wide div.tall {
  width: 90.08264463%;
  margin-left: 4.95867769%;
  margin-right: 4.95867769%;
}
.base div.wide div.tiny {
  width: 40.08264463%;
  margin-left: 4.95867769%;
  margin-right: 4.95867769%;
}
div.main div.seam {
  width: 98.29059829%;
  margin-left: 0.74786325%;
  margin-right: 0.74786325%;
}
div.base div.seam {
  width: 95.04132231%;
  margin-left: 2.47933884%;
  margin-right: 2.47933884%;
}
h1 {
  color: #fff;
  text-align: left;
  padding: 0;
  border: none;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */